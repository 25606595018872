import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import project from '../../assets/images/projects/kap-tanzania-kenya.jpg'
import project1 from '../../assets/images/projects/patac1.jpg'
import project2 from '../../assets/images/projects/patac2.jpg'
import project3 from '../../assets/images/projects/patac3.jpg'
import {Link} from 'gatsby'
import Lightbox from 'react-image-lightbox'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Data collection KAP Tanzania - Kenya" 
                homePageText="Data analysis" 
                homePageUrl="/data-analysis" 
                activePageText="Data collection KAP Tanzania - Kenya" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Partner:</span>
                        <a href="https://www.weworld-gvc.it/" target="_blank" rel="noreferrer">WeWorld GVC</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
                        Tanzania and Kenya
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Technlogy:</span>
                        Dewco
                        </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Duration:</span>
                        3 months 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Status:</span>
                        Completed
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={project} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Data analysis</span>
                            <h3>The project</h3>
                            <p>In order to be able to plan the COVID-19 response in Tanzania and Kenya, GVC-WW needed to perform a Knowledge, Attitude and Practice (KAP) Survey in the villages where they already operated.  
They prepared a 200 questions survey that was digitised into an instance of DEWCO, allowing  10 social workers to perform telephone interviews and immediately insert the answers into the platform. more than 700 interviews were collected in two weeks.  
The data collected were then exported and analysed using Google Data Studio.
</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}

const images = [
    (project1),
    (project2),
    (project3),
];

const Gallery = () => {
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    return (
        <section>
            <div className="gallery-area pt-100 pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(false); 
                                        setPhotoIndex(0);}
                                    }
                                >
                                    <img src={project1} alt="event" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(true); 
                                        setPhotoIndex(1);}
                                    }
                                >
                                    <img src={project2} alt="event" />
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-gallery-item">
                                <Link 
                                    to="#"
                                    onClick={e => {
                                        e.preventDefault(); 
                                        setIsOpenImage(true); 
                                        setPhotoIndex(2);}
                                    }
                                >
                                    <img src={project3} alt="event" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Lightbox */}
                {isOpenImage && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setIsOpenImage(false)}
                        onMovePrevRequest={() =>
                            setPhotoIndex((photoIndex + images.length - 1) % images.length)
                        }
                        onMoveNextRequest={() =>
                            setPhotoIndex((photoIndex + 1) % images.length)
                        }
                    />
                )}
            </div>
            </section>
    );
}
